















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SessionScore } from "@/components/charts";

@Component({ components: { SessionScore } })
export default class SessionHeader extends Vue {
  @Prop({ default: () => "" }) title!: string;
  @Prop({ default: () => 0 }) score!: number;

  get sessionScore(): number {
    return Math.round(this.score * 10000) / 100;
  }
}
